import { Bar } from "react-chartjs-2";
import styles from "./statsModalStyles.module.scss";
export default function ChartComponent(label, globalLimit, userLimit) {
  const options = {
    plugins: {
      title: {
        display: false,
        text: `Wykorzystanie limitu: ${label}`,
        font: {
          family: "'Century Gothic', 'Prompt', sans-serif",
          weight: "500",
          size: "15px",
        },
      },
      tooltip: {
        callbacks: {
          title: (item) => "",
          label: (item) => `${item.formattedValue} ${globalLimit.unit}`,
        },
      },
      legend: {
        display: false,
        labels: {
          // This more specific font property overrides the global property
          font: {
            family: "'Century Gothic', 'Prompt', sans-serif",
          },
        },
      },
    },

    aspectRatio: 1,
    tooltips: {
      mode: "index",
      position: "cursor",
      intersect: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: false,
        stacked: true,
        ticks: {
          font: {
            family: "'Century Gothic', 'Prompt', sans-serif",
            size: 10,
          },
        },
      },
      y: {
        stacked: true,
        ticks: {
          callback: function (val, index) {
            // Hide every 2nd tick label
            return val % 1 == 0 ? this.getLabelForValue(val) : "";
          },

          font: {
            family: "'Century Gothic', 'Prompt', sans-serif",
            size: 10,
          },
        },
      },
    },
  };

  const data = {
    labels: [label],
    datasets: [
      {
        label: "Wykorzystane",
        data: !userLimit ? null : [userLimit],
        backgroundColor: "rgb(227, 30, 36)",
      },
      {
        label: "Pozostałe",
        data: !globalLimit
          ? null
          : globalLimit.limit > userLimit
          ? [globalLimit.limit - userLimit]
          : [0],
        backgroundColor: "rgba(227, 30, 37, 0.329)",
      },
    ],
  };

  return (
    <div className={styles.chart}>
      <Bar options={options} data={data} />
    </div>
  );
}
