import QrCodes from "./components/QrCodes";
import StatsModal from "./components/StatsModal";
import styles from "./style.module.scss";
import qrStyles from "./components/qrCodesStyles.module.scss";
import { message } from "antd";
import Button from "@mui/material/Button";
import { useReactToPrint } from "react-to-print";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { ReactComponent as GnieznoLogo } from "./assets/gnieznologo.svg";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import { ThemeProvider } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import PrintIcon from "@mui/icons-material/Print";
import ReplayIcon from "@mui/icons-material/Replay";
import { theme } from "./themeGenerator";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";

import PSZOKMAP from "./assets/pdfs/pszok_mapa.pdf";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import HelpIcon from "@mui/icons-material/Help";
import InfoIcon from "@mui/icons-material/Info";
import GavelIcon from "@mui/icons-material/Gavel";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { useNavigate } from "react-router-dom";

import PrzydatneInfo from "./assets/pdfs/pszok_przydatne_informacje.pdf";
import regQR from "./assets/pdfs/RegulaminQRKodow.pdf";
import regPszok from "./assets/pdfs/RegulaminPSZOK.pdf";
import KlazulaInf from "./assets/pdfs/KlauzulaInf.pdf";
import cennik from "./assets/pdfs/cennik.pdf";
import pszokInstrukcja from "./assets/pdfs/PSZOK_instrukcja.pdf";

function App() {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [data, setData] = useState(null);
  const [limits, setLimits] = useState(null);
  const [currentModal, setCurrentModal] = useState(null);

  const [isModalStatsVisible, setIsModalStatsVisible] = useState(false);
  const printRef = useRef();
  const navigate = useNavigate();
  const [qrCheckbox, setQrCheckbox] = useState(false);
  const [dataCheckbox, setDataCheckbox] = useState(false);

  const handleQrCheckboxChange = (event) => {
    setQrCheckbox(event.target.checked);
  };

  const handleDataCheckboxChange = (event) => {
    setDataCheckbox(event.target.checked);
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    bodyClass: qrStyles.toPrintBody,
  });

  message.config({
    top: 100,
    duration: 2,
    maxCount: 3,
  });

  useEffect(() => {
    const savedVals = localStorage.getItem("mem");
    const initialValue = JSON.parse(savedVals);

    if (savedVals) {
      sendRequest(initialValue, true);
    }
  }, []);

  const displayError = (error) => {
    message.error({ content: error, className: styles.errorAlert });
  };

  const sendRequest = async (
    objToSend,
    init = false,
    initStatsModal = false
  ) => {
    setLoading(true);
    var string =
      "/user/fetchQRData?declaration_number=" + objToSend.declaration_number;

    string += "&id_number=" + objToSend.id_number;

    string += "&type=" + objToSend.type;

    const backend =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_API_URL
        : process.env.REACT_APP_PROD_API_URL;

    axios
      .get(backend + string)
      .then((res) => {
        setLimits(res.data.limits);
        setLoading(false);
        setErrors(null);
        localStorage.setItem("mem", JSON.stringify(objToSend));
        localStorage.setItem("qr", res.data.qr_string);
        setData(res.data.qr_string);

        if (initStatsModal) {
          setIsModalStatsVisible(true);
        }
      })
      .catch((error) => {
        console.log(error);
        if (init) {
          if (navigator.onLine) {
            localStorage.removeItem("mem");
            setLoading(false);
          } else {
            setData(localStorage.getItem("qr"));
          }
        } else {
          if (navigator.onLine) {
            setLoading(false);
            //setErrors(error.response.data.error);
            displayError(error.response.data.error);
            setData(null);
          } else {
            var mem = JSON.parse(localStorage.getItem("mem"));
            if (
              mem.declaration_number === objToSend.declaration_number &&
              mem.id_number === objToSend.id_number
            ) {
              setData(localStorage.getItem("qr"));
            }
          }
        }
      });
  };

  const onSubmit = async () => {
    let object = {};
    object["id_number"] = idNumber;
    object["declaration_number"] = declarationNumber;
    object["type"] = declarationType;
    //console.log(object);
    sendRequest(object);
  };

  const onSubmitWithLimits = async () => {
    let object = {};
    object["id_number"] = idNumber;
    object["declaration_number"] = declarationNumber;
    object["type"] = declarationType;
    //console.log(object);
    sendRequest(object, false, true);
  };

  const removeQrData = () => {
    setData(null);
    setLimits(null);
  };

  const [declarationNumber, setDeclarationNumber] = useState(undefined);
  const handleDeclarationNumberChange = (event) => {
    setDeclarationNumber(event.target.value);
  };

  const [idNumber, setIdNumber] = useState(undefined);
  const handleIdNumberChange = (event) => {
    setIdNumber(event.target.value);
  };

  const [declarationType, setDeclarationType] = useState("regular");

  const handleDeclarationTypeChange = (event) => {
    setDeclarationType(event.target.value);
  };

  const handleCloseModals = () => {
    setCurrentModal(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={styles.mainContainer}>
        <div className={styles.landingText}>
          <div className={styles.wLogo}>
            <GnieznoLogo className={styles.logo} />
            <p className={styles.title}>Gnieźnieński PSZOK</p>
          </div>

          <p className={styles.desc}>Generator Kodów QR</p>
        </div>

        {data === null && (
          <>
            <div className={styles.inputs}>
              <FormControl className={styles.declarationTypeForm}>
                <FormLabel
                  className={styles.boldTxt}
                  color="secondary"
                  id="demo-radio-buttons-group-label"
                >
                  Wybierz rodzaj deklaracji
                </FormLabel>
                <RadioGroup
                  value={declarationType}
                  onChange={handleDeclarationTypeChange}
                >
                  <FormControlLabel
                    value="regular"
                    control={<Radio color="secondary" />}
                    label="zabudowa jednorodzinna"
                  />
                  <FormControlLabel
                    value="flat"
                    control={<Radio color="secondary" />}
                    label="zabudowa wielorodzinna"
                  />
                </RadioGroup>
              </FormControl>

              <div className={styles.checkboxes}>
                <FormControl component="fieldset">
                  <FormGroup aria-label="position" column>
                    <FormControlLabel
                      className={styles.tosLabels}
                      control={
                        <Checkbox
                          value={qrCheckbox}
                          onChange={handleQrCheckboxChange}
                        />
                      }
                      label="zapoznałem/am się z regulaminem wydawania QR kodów"
                    />
                    <div className={styles.tos}>
                      <a
                        //onClick={() => setCurrentModal(regQR)}
                        href={regQR + "?download=false"}
                        target="_blank"
                      >
                        Regulamin wydawania kodów QR
                      </a>
                    </div>{" "}
                    <FormControlLabel
                      className={styles.tosLabels}
                      control={
                        <Checkbox
                          value={dataCheckbox}
                          onChange={handleDataCheckboxChange}
                        />
                      }
                      label={
                        "zapoznałem/am się z kauzulą dotyczącą przetwarzania danych osobowych"
                      }
                    />
                    <div className={styles.tos}>
                      <a
                        //onClick={() => setCurrentModal(KlazulaInf)}
                        href={KlazulaInf + "?download=false"}
                        target="_blank"
                      >
                        Klauzula dotycząca przetwarzania danych osobowych
                      </a>
                    </div>{" "}
                  </FormGroup>
                </FormControl>
              </div>

              <TextField
                className={styles.txtField}
                label="Identyfikator płatnika"
                inputProps={{ maxLength: 15 }}
                color="secondary"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">S0</InputAdornment>
                  ),
                }}
                value={declarationNumber}
                onChange={handleDeclarationNumberChange}
                margin="dense"
              />

              <TextField
                className={styles.txtField}
                inputProps={{ maxLength: 15 }}
                label={
                  declarationType === "regular"
                    ? "Ostatnie 5 cyfr PESELU"
                    : "Numer identyfikacyjny"
                }
                color="secondary"
                value={idNumber}
                onChange={handleIdNumberChange}
                margin="dense"
              />

              <div className={styles.doneButtonContainer}>
                <LoadingButton
                  onClick={onSubmit}
                  color="primary"
                  loading={loading}
                  variant="contained"
                  disabled={!qrCheckbox || !dataCheckbox}
                >
                  WYGENERUJ KOD QR
                </LoadingButton>

                <LoadingButton
                  onClick={onSubmitWithLimits}
                  color="primary"
                  loading={loading}
                  variant="contained"
                  disabled={!qrCheckbox || !dataCheckbox}
                >
                  SPRAWDŹ LIMITY
                </LoadingButton>
              </div>
            </div>
          </>
        )}

        {data != null ? (
          <div className={styles.qrContainer}>
            <QrCodes ref={printRef} className={styles.qrHolder} data={data} />
            <Button
              className={styles.button}
              variant="outlined"
              startIcon={<ReplayIcon />}
              color="primary"
              onClick={removeQrData}
            >
              Wygeneruj ponownie
            </Button>

            <StatsModal
              isModalVisible={isModalStatsVisible}
              setIsModalVisible={setIsModalStatsVisible}
              limits={limits}
            />

            <Button
              className={styles.button}
              variant="outlined"
              startIcon={<PrintIcon />}
              color="secondary"
              onClick={handlePrint}
            >
              Drukuj
            </Button>
          </div>
        ) : (
          <></>
        )}

        {/* <a
          className={styles.btmButton}
          onClick={() => {
            navigate("/wyszukiwarka");
          }}
          target="_blank"
        >
          <SearchIcon className={styles.icon} /> WYSZUKIWARKA ODPADÓW
        </a> */}

        <a
          className={styles.btmButton}
          href={PSZOKMAP + "?download=false"}
          target="_blank"
        >
          <LocationOnIcon className={styles.icon} /> PLAN PSZOK
        </a>
        <a
          className={styles.btmButton}
          //onClick={() => setCurrentModal("instrukcja")}
          href={pszokInstrukcja + "?download=false"}
          target="_blank"
        >
          <HelpIcon className={styles.icon} /> INSTRUKCJA OBSŁUGI PANELU
        </a>
        <a
          className={styles.btmButton}
          //onClick={() => setCurrentModal("przydatne")}
          href={PrzydatneInfo + "?download=false"}
          target="_blank"
        >
          <InfoIcon className={styles.icon} /> PRZYDATNE INFORMACJE
        </a>
        <a
          className={styles.btmButton}
          //onClick={() => setCurrentModal(regPszok)}
          href={regPszok + "?download=false"}
          target="_blank"
        >
          <GavelIcon className={styles.icon} /> REGULAMIN PSZOK
        </a>

        <a
          className={styles.btmButton}
          //onClick={() => setCurrentModal(regPszok)}
          href={cennik + "?download=false"}
          target="_blank"
        >
          <LocalOfferIcon className={styles.icon} /> CENNIK OPŁAT
        </a>
      </div>
    </ThemeProvider>
  );
}

export default App;
