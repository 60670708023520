import React from "react";
import styles from "./qrCodesStyles.module.scss";
import mapIMG from "../assets/print_imgs/mapscr.jpg";
import usefulIMG from "../assets/print_imgs/przydatnescr.jpg";
//var QRCode = require("qrcode.react");
import { QRCode } from "react-qrcode-logo";
import GnieznoLogo from "../assets/gnieznologo.svg";

const ComponentToPrint = React.forwardRef((props, ref) => {
  return (
    <>
      <div className={styles.qrWrapper} ref={ref}>
        <div className={styles.title}>
          <p>Twój kod QR</p>
        </div>
        <div id="qrC" className={styles.qr}>
          <QRCode
            removeQrCodeBehindLogo
            ecLevel="H"
            logoImage={GnieznoLogo}
            eyeRadius={[
              {
                // top/left eye
                outer: [0, 10, 0, 10],
                inner: [0, 10, 0, 10]
              },
              [10, 10, 10, 0], // top/right eye
              [10, 0, 10, 10] // bottom/left
            ]}
            size={300}
            value={props.data}
            fgColor="#1F1F1F"
            renderAs="svg"
          />
        </div>
        <div className={styles.invisibleContent}>
          <div className={styles.logo}>
            <img src={GnieznoLogo}></img>
            <p className={styles.txt}>Gnieźnieński PSZOK</p>
          </div>
          <img className={styles.mapIMG} src={mapIMG}></img>
          <img className={styles.usefulIMG} src={usefulIMG}></img>
        </div>{" "}
        <p className={styles.qrStringified}>{props.data}</p>
      </div>
    </>
  );
});

export default ComponentToPrint;
